<template>
  <v-expansion-panel
    class="mt-0 bg-secondary-o-50 mb-3 rounded-lg"
    @click="togglePanel"
  >
    <v-expansion-panel-header class="ps-0">
      <span class="font-weight-black font-size-lg ms-5">
        <!-- <v-icon class="mr-3">mdi-{{ headerIcon }}-circle-outline</v-icon> -->
        {{ warehouseDetails.text }}
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="ps-0">
      <div class="ms-5 border-top">
        <div
          v-if="!serverDataLoaded"
          class="d-flex justify-content-center mt-5"
        >
          <v-progress-circular
            :size="50"
            :width="5"
            color="#7e04b7"
            indeterminate
          ></v-progress-circular>
        </div>
        <form v-else-if="serverDataLoaded" class="form-body mt-5">
          <!-- lock location while doing cycle count -->
          <v-row class="mt-0">
            <v-col md="6">
              <v-checkbox
                v-model="formData.is_lock_location_while_doing_cycle_count"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Lock location while doing cycle count
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- Enable blind cycle count -->
          <v-row class="mt-0">
            <v-col md="6">
              <v-checkbox
                v-model="formData.is_blind_cycle_count"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Enable blind cycle count
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>

          <!-- Block Cycle Count for locations with Pending Tasks -->
          <v-row class="mt-0">
            <v-col md="6">
              <v-checkbox
                v-model="
                  formData.block_cycle_count_for_locations_with_pending_tasks
                "
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Block Cycle Count for locations with Pending Tasks
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>

          <!-- Check scanned sku with master sku only -->
          <v-row class="mt-0">
            <v-col md="6">
              <v-checkbox
                v-model="formData.is_check_scanned_sku_with_master_sku_only"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Check scanned sku with master sku only
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- Submit -->
          <v-row class="justify-center mb-3">
            <v-col md="2" class="justify-content-end">
              <!-- <v-btn dark color="purple" @click="submitConfiguration">
                Submit
              </v-btn> -->
              <button
                class="btn btn-info px-5 py-3 ls1"
                @click.prevent="submitConfiguration"
              >
                Submit
              </button>
            </v-col>
          </v-row>
        </form>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";

export default {
  name: "ExpansionPanel",
  props: {
    warehouseDetails: { type: Object, required: true },
  },
  components: {},
  data() {
    return {
      expanded: false,
      serverData: null,
      serverDataLoaded: false,

      formData: {
        is_lock_location_while_doing_cycle_count: false,
        is_blind_cycle_count: false,
        block_cycle_count_for_locations_with_pending_tasks: false,
        is_check_scanned_sku_with_master_sku_only: false,
      },
    };
  },

  methods: {
    togglePanel: function () {
      if (!this.expanded) {
        this.loadDataFromServer();
      }
      this.expanded = !this.expanded;
    },

    loadDataFromServer: function () {
      this.serverDataLoaded = false;
      const payload = {
        type: "inventory",
        warehouse_id: this.warehouseDetails.index,
      };
      return new Promise((resolve) => {
        ApiService.post(
          `/warehouse_management/main_configuration/create`,
          payload
        )
          .then(({ data }) => {
            this.serverData = data;
            this.setConfigurationData(data.configurations.details);
            this.serverDataLoaded = true;
            resolve(data);
          })
          .catch();
      });
    },

    setConfigurationData: function (data) {
      this.formData = {
        // ...this.formData,
        is_lock_location_while_doing_cycle_count: data[0].value,
        is_blind_cycle_count: data[1].value,
        block_cycle_count_for_locations_with_pending_tasks: data[2].value,
        is_check_scanned_sku_with_master_sku_only: data[3].value,
      };
    },

    submitConfiguration: function () {
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      const data = this.formData;
      const payload = {
        type: "inventory",
        warehouse_id: this.warehouseDetails.index,
        configurations: [
          {
            key: "is_lock_location_while_doing_cycle_count",
            value: data.is_lock_location_while_doing_cycle_count,
            apply_rule_type: "all",
            customers: [],
          },
          {
            key: "is_blind_cycle_count",
            value: data.is_blind_cycle_count,
            apply_rule_type: "all",
            customers: [],
          },
          {
            key: "block_cycle_count_for_locations_with_pending_tasks",
            value: data.block_cycle_count_for_locations_with_pending_tasks,
            apply_rule_type: "all",
            customers: [],
          },
          {
            key: "is_check_scanned_sku_with_master_sku_only",
            value: data.is_check_scanned_sku_with_master_sku_only,
            apply_rule_type: "all",
            customers: [],
          },
        ],
      };
      this.$store.commit(SET_PAGE_LOADING, true);
      return new Promise(() => {
        ApiService.post(
          `/warehouse_management/main_configuration/store`,
          payload
        )
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: "The Configuration settings are updated",
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            // this.expanded = false;
          })
          .catch()
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      });
    },
  },
  // },
};
</script>
